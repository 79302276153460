import { isAbsoluteUrl } from '@/src/utils/isAbsoluteUrl';

import styles from './styles.module.scss';

import clsx from 'clsx';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
  ForwardedRef,
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
  useMemo,
} from 'react';

type BaseProps = {
  children: React.ReactNode;
  variant: 'primary' | 'normal' | 'danger';
  size?: 'medium' | 'small';
  underline?: boolean;
  disabled?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
};

type ButtonProps = BaseProps & {
  anchor?: never;
  buttonref?: ForwardedRef<HTMLButtonElement>;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
type AnchorProps = BaseProps & {
  anchor: true;
  anchorref?: ForwardedRef<HTMLAnchorElement>;
  isOpenAnotherTab?: boolean;
};

type Props =
  | (ButtonProps &
      Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof ButtonProps>)
  | (AnchorProps &
      Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof AnchorProps>);

export const TextButton: React.FC<Props> = ({
  size = 'medium',
  underline = false,
  disabled = false,
  variant,
  prefix,
  suffix,
  children,
  ...props
}) => {
  const router = useRouter();
  const className = useMemo(
    () =>
      clsx(
        styles.base,
        styles[size],
        styles[variant],
        underline && styles.underline,
        disabled && styles.disabled,
      ),
    [size, variant, underline, disabled],
  );

  const openAnotherTabProps: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > = props.anchor && props.isOpenAnotherTab
    ? {
        target: '_blank',
        ref: 'noreferrer',
      }
    : {};

  return props.anchor ? (
    <NextLink passHref legacyBehavior href={props.href ?? ''}>
      <a
        className={className}
        {...openAnotherTabProps}
        style={{
          ...props.style,
        }}
        ref={props.anchorref}
        // 別タブで相対指定のリンクを開く場合、速度のためにaタグによる遷移ではなくwindow.openを使用する。
        // 理由は不明だが、window.openにより別タブで開いた場合の認証を飛ばせるため速度が改善される
        onClick={
          props.isOpenAnotherTab && props.href && !isAbsoluteUrl(props.href)
            ? (e) => {
                if (props.href === undefined) return;
                e.preventDefault();
                window.open(`${router.basePath}${props.href}`, '_blank');
              }
            : undefined
        }
      >
        {prefix}
        {children}
        {suffix}
      </a>
    </NextLink>
  ) : (
    <button
      {...props}
      className={className}
      style={{
        ...props.style,
      }}
      ref={props.buttonref}
      // form中で唯一のbuttonはsubmitとして扱われてしまう。
      // これによって意図しない挙動になることがほとんどのため、typeを指定しておくことで回避する。
      type={props.type ?? 'button'}
    >
      {prefix}
      {children}
      {suffix}
    </button>
  );
};
