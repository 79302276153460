import { Button } from '@/src/components/foundations/Buttons/Button';
import { Modal } from '@/src/components/foundations/Utils/ModalDialog/Modal';

import styles from './styles.module.scss';
import { Props } from './types';

import clsx from 'clsx';

export const ModalDialog: React.FC<Props> = (props) => {
  const onClickBackground = () => {
    if (props.disabledOnClickBackground) return null;
    return props.cancelButton.onClick();
  };

  return (
    <Modal isShown={props.isShown} onClickBackground={onClickBackground}>
      <div
        className={clsx(
          styles.base,
          props.size ? styles[props.size] : styles.medium,
          props.className,
        )}
      >
        <div className={styles.header}>
          <p className={styles.title}>{props.title}</p>
        </div>
        {props.headerContents}
        <div id="modal-main" className={styles.main}>
          {props.children}
        </div>
        <div className={styles.footer}>
          {props.subActionButton && (
            <Button
              color={props.subActionButton.color ?? 'primary'}
              onClick={props.subActionButton.onClick}
              type={props.subActionButton.type ?? 'skeleton'}
            >
              {props.subActionButton.children}
            </Button>
          )}
          <div className={styles.mainButtonsWrapper}>
            {props.cancelButton.children && (
              <Button
                color={props.cancelButton.color ?? 'basic'}
                onClick={props.cancelButton.onClick}
                className={styles.cancelButton}
              >
                {props.cancelButton.children}
              </Button>
            )}
            <div className={styles.okButtonWrapper}>
              {Array.isArray(props.okButton) ? (
                props.okButton.map((value, index) => (
                  <Button
                    className={styles.okButton}
                    key={index}
                    color={value.color ?? 'primary'}
                    {...value}
                  >
                    {value.children}
                  </Button>
                ))
              ) : (
                <Button
                  className={styles.okButton}
                  color={props.okButton.color ?? 'primary'}
                  {...props.okButton}
                >
                  {props.okButton.children}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
