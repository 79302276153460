import { ModalPresenter } from './presenter';
import { PresenterProps } from './types';

import { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

export const Modal: React.FC<PresenterProps> = (props) => {
  const modalRootRef = useRef<HTMLElement>();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    modalRootRef.current = document.getElementById('modal-root')!;

    setIsMounted(true);
  }, []);

  if (modalRootRef.current == null || !isMounted || !props.isShown) {
    return null;
  }

  return createPortal(
    <ModalPresenter {...props}>{props.children}</ModalPresenter>,
    modalRootRef.current,
  );
};
