import { Scroll } from '@/src/utils/scroll';

import { useCallback, useState } from 'react';

export type Modal = {
  isShown: boolean;
  show: VoidFunction;
  hide: VoidFunction;
  scrollTop: VoidFunction;
};
export const useModal = (): Modal => {
  const [isShown, setIsShown] = useState(false);
  const show = useCallback(() => {
    Scroll.forbid();

    setIsShown(true);
  }, []);
  const hide = useCallback(() => {
    setIsShown(false);

    Scroll.allow();
  }, []);
  const scrollTop = useCallback(() => {
    Scroll.scrollTop();
  }, []);

  return {
    isShown,
    show,
    hide,
    scrollTop,
  };
};
