import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import styles from '../styles.module.scss';
import { Props as TooltipProps } from '../types';

type Props = {
  isVisible: boolean;
  parentRect: DOMRect | null;
  triggerType: Required<TooltipProps['triggerType']>;
} & Pick<TooltipProps, 'children' | 'direction' | 'size'>;

const directionToClassName = (direction?: Props['direction']) => {
  switch (direction) {
    case 'bottomLeft':
      return [styles.bottom, styles.bottomLeft];
    case 'bottomRight':
      return [styles.bottom, styles.bottomRight];
    case 'left':
      return [styles.left];
    case 'right':
      return [styles.right];
    default:
      return [styles[direction ?? 'bottom']];
  }
};

export const TooltipPortal: React.FC<Props> = (props) => {
  const portalRootRef = useRef<HTMLElement>();

  useEffect(() => {
    portalRootRef.current =
      document.getElementById('tooltip-root') ?? undefined;
  }, []);

  if (!props.isVisible || !props.parentRect || !portalRootRef.current) {
    return null;
  }

  return createPortal(
    <div
      style={{
        position: 'absolute',
        zIndex: 'var(--z-index-tooltip)',
        // ポップアップの起点の位置、大きさをtriggerする要素に重ねることで表示位置の調整を容易にしている
        top: props.parentRect.top,
        left: props.parentRect.left,
        width: props.parentRect.width,
        height: props.parentRect.height,
        // trigger要素に重なって描画されるこの要素がスクロールやクリックの邪魔にならないようにするための対応
        pointerEvents: 'none',
      }}
    >
      <div
        className={clsx(
          styles.descriptionBase,
          ...directionToClassName(props.direction),
        )}
      >
        <div
          className={clsx(
            styles.description,
            styles[props.size],
            props.triggerType === 'text'
              ? styles.triggerText
              : styles.triggerIcon,
            ...directionToClassName(props.direction),
          )}
        >
          {props.children}
        </div>
      </div>
    </div>,
    portalRootRef.current,
  );
};
