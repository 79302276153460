import { RightOrLeftLabel } from './components/RightOrLeftLabel';
import { TopLabel } from './components/TopLabel';
import { TopLabelType, RightOrLeftLabelType } from './types';

type Props = TopLabelType | RightOrLeftLabelType;

export const Label: React.FC<Props> = (props) => {
  return props.position === 'top' ? (
    <TopLabel {...props} />
  ) : (
    <RightOrLeftLabel {...props} />
  );
};
