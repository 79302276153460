import { ExtractColor } from '@/src/types/styles';

import styles from './styles.module.scss';

import clsx from 'clsx';
import { forwardRef } from 'react';

type Color = ExtractColor<'primary' | 'secondary'>;

type Props<Name extends string> = {
  color?: Color;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tabIndex?: number;
  isDisabled?: boolean;
} & (
  | {
      isRaw?: false;
      name: Name;
      value?: string;
    }
  | {
      isRaw: true;
      isChecked: boolean;
    }
);

const CheckboxContent = forwardRef<HTMLInputElement, Props<string>>(
  (props, ref) => {
    const { color = 'primary' } = props;

    const inputProps: React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > = !props.isRaw
      ? {
          name: props.name,
          value: props.value,
        }
      : {
          checked: props.isChecked,
        };

    return (
      <div className={styles.base}>
        <input
          type="checkbox"
          className={clsx(styles.input, styles[color])}
          ref={ref}
          onChange={props.onChange}
          tabIndex={props.tabIndex}
          disabled={props.isDisabled}
          {...inputProps}
        />
      </div>
    );
  },
);
export const Checkbox: <Name extends string>(
  props: React.PropsWithoutRef<Props<Name>> &
    React.RefAttributes<HTMLInputElement>,
) => ReturnType<typeof CheckboxContent> = CheckboxContent;
