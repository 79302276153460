import { FetchError, isStatusCode } from '@/src/error/fetchError/FetchError';
import { checkIsCloudApp } from '@/src/utils/checkIsAppEnv';

import { TRPCClientError } from '@trpc/client';
import useSWR, { Fetcher, SWRConfiguration } from 'swr';

export type AsyncFetchResponse<T> = {
  data: T | undefined;
  error: FetchError | undefined;
  mutate: (
    data?: T | Promise<T>,
    shouldRevalidate?: boolean,
  ) => Promise<T | undefined>;
};

const extractSubCode = async (
  response: Response,
): Promise<string | undefined> => {
  const body = (await response
    .json()
    .catch((err) => console.error(err))) as unknown;

  if (body != null && typeof body === 'object' && 'sub_code' in body) {
    return (body as { sub_code: string }).sub_code;
  }
};

type Key = string | [string, object] | null;
export const useAsyncFetch = <T>(
  key: Key,
  fetcher: () => Promise<T>,
  onErrorRetry?: SWRConfiguration<
    T,
    FetchError,
    Fetcher<T, Key>
  >['onErrorRetry'],
  options?: SWRConfiguration<T, FetchError, Fetcher<T, Key>>,
): AsyncFetchResponse<T> => {
  const { data, error, mutate } = useSWR<T, FetchError>(
    key,
    async () => {
      return fetcher().catch(async (error) => {
        // useAsyncFetchでは通常、openapi-generator で生成されたコードを fetcher から呼ぶことを想定している。
        // openapi-generator で生成されたコードでは、リクエストエラー時 Response インスタンスを throw する。
        // リクエストエラーではなくバリデーションのエラーなどの場合は Error を継承した インスタンスを throw する。
        if (error instanceof Response) {
          const subCode = await extractSubCode(error);

          throw new FetchError(
            isStatusCode(error.status) ? error.status : 500,
            // エラーメッセージには500に丸めないステータスコードを設定する
            `FetchError: HTTP status ${error.status}${
              subCode ? ` (${subCode})` : '(no subcode)'
            } in GET ${error.url}`,
          );
        } else if (error instanceof TRPCClientError) {
          const data = error.data as unknown;
          // tRPCクライアントでのエラーの場合
          if (
            data !== null &&
            typeof data === 'object' &&
            'httpStatus' in data &&
            typeof data.httpStatus === 'number' &&
            isStatusCode(data.httpStatus)
          ) {
            throw new FetchError(data.httpStatus, error.message, {
              cause: error,
            });
          } else {
            throw new FetchError(500, error.message, { cause: error });
          }
        } else if (error instanceof Error) {
          // リクエストに失敗したエラーではないので、本来はFetchErrorではないが、
          // エラー画面表示とSentryへの記録のためにFetchErrorを流用している。
          // 現在は両者はFetchErrorに対してだけ実施されており、他のエラーを受け取るようになっていない。
          throw new FetchError(500, error.message, { cause: error });
        } else {
          throw new FetchError(500, JSON.stringify(error));
        }
      });
    },
    // デフォルトでexponential backoff アルゴリズムによるリトライが行われるが、onErrorRetryが指定されている場合はそれを優先する。
    // undefinedを渡すとリトライ時にエラーになってしまうので、指定がない場合はキーも設定しないようにしている。
    // https://swr.vercel.app/ja/docs/error-handling#error-retry
    onErrorRetry ? { onErrorRetry, ...options } : { ...options },
  );

  if (error && !checkIsCloudApp()) {
    console.error(error);
  }

  return { data, error, mutate };
};
